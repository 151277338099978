import React from 'react';
import { connect } from 'react-redux';
import { ISearchState, IInfoLayer } from '../types';
import { showInfoLayer, hideInfoLayer } from '../actions';


const mapStateToProps = (rootReducer:ISearchState, props:IInfoLayer):IInfoLayer => {
    const { infoLayerState } = rootReducer
    return {
        ...infoLayerState
    }
}

const mapDispathToProps = (dispatch:any) => {
    return {
        showInfoLayer: (payload:IInfoLayer) => dispatch(showInfoLayer(payload)),
        hideInfoLayer: () => dispatch(hideInfoLayer())
    }
}

const InfoLayer = ({text, timer, active, hideInfoLayer, notShown }:IInfoLayer) => {
    if (notShown)
        return (
            <div/>
        )
    if (active && timer !== 0) {
        setTimeout( () => {
            hideInfoLayer()
        }, (timer * 1000))
    } else {
        hideInfoLayer()
    }

    return (
        <div className="InfoLayer">
            <div className="wrapper">
                <div className="block">
                    <p className="text">{text}</p>
                    <span className="close-botton" onClick={hideInfoLayer}><i className="fas fa-times"></i></span>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispathToProps)(InfoLayer)