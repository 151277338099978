export const INIT_PRODUCTS_SEARCH = "INIT_PRODUCTS_SEARCH"
export const SET_PRODUCTS_SEARCH = "SET_PRODUCTS_SEARCH"
export const SET_PRODUCTS_SEARCH_SUCCESS = "SET_PRODUCTS_SEARCH_SUCCESS"
export const SET_PRODUCTS_SEARCH_FAIL = "SET_PRODUCTS_SEARCH_FAIL"
export const SET_PRODUCTS_SEARCH_END = "SET_PRODUCTS_SEARCH_END"
export const SET_PRODUCTS_FAKE = "SET_PRODUCTS_FAKE"
export const SHOW_INFO_BLOCK = "SHOW_INFO_BLOCK"
export const HIDE_INFO_BLOCK = "HIDE_INFO_BLOCK"

export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART"
export const REMOVE_PRODUCT_FROM_CART = "REMOVE_PRODUCT_FROM_CART"

export const PLUS_PRODUCT_TO_CART = "PLUS_PRODUCT_TO_CART"
export const MINUS_PRODUCT_TO_CART = "MINUS_PRODUCT_TO_CART"


export const CLEAR_CART = "CLEAR_CART"
export const PLUS_ITEM_CART = "PLUS_ITEM_CART"
export const MINUS_ITEM_CART = "MINUS_ITEM_CART"
export const ADD_ITEM_TOP_CART = "ADD_ITEM_TOP_CART"
export const REMOVE_ITEM_TOP_CART = "REMOVE_ITEM_TOP_CART"
export const CLEAR_TOP_CART = "CLEAR_TOP_CART"

export const CHANGE_REGISTER_FORM_FIELD = "CHANGE_REGISTER_FORM_FIELD"
export const SUBMIT_REGISTER_FORM = "SUBMIT_REGISTER_FORM"
export const CLEAN_REGISTER_FORM = "CLEAN_REGISTER_FORM"
export const VALIDATE_REGISTER_FORM = "VALIDATE_REGISTER_FORM"
export const UNVALIDATE_REGISTER_FORM = "UNVALIDATE_REGISTER_FORM"

export const CHANGE_LOGIN_FORM_FIELD = "CHANGE_LOGIN_FORM_FIELD"
export const CLEAN_LOGIN_FORM = "CLEAN_LOIN_FORM"
export const SET_LOGIN_ERRORS = "SET_LOGIN_ERRORS"

export const SET_USER_DATA = "SET_USER_DATA"
export const SET_USER_FULL_DATA = "SET_USER_FULL_DATA"

export const SET_PROMOTIONS = "SET_PROMOTIONS"
export const SET_NEWS = "SET_NEWS"
export const SET_NEWS_POST = "SET_NEWS_POST"

export const SET_SETTINGS = "SET_SETTINGS"
export const SET_WAREHOUSES = "SET_WAREHOUSES"

export const CHANGE_PRIVACYBLOCK_EDITABLE = "CHANGE_PRIVACYBLOCK_EDITABLE"
export const CHANGE_GENERAL_EDITABLE = "CHANGE_GENERAL_EDITABLE"
export const CHANGE_USER_FORM_FIELD = "CHANGE_USER_FORM_FIELD"
export const SET_SETTINGS_FORM = "SET_SETTINGS_FORM"
export const SET_HISTORY = "SET_HISTORY"

export const CHANGE_SEARCH_KEY = "CHANGE_SEARCH_KEY"
export const CHANGE_FILTER = "CHANGE_FILTER"
export const APPLY_DISCOUNT = "APPLY_DISCOUNT"
export const CHANGE_PRODUCT_ITEM_QUANTITY = "CHANGE_PRODUCT_ITEM_QUANTITY"

export const SET_AUTOFILL = "SET_AUTOFILL"