export const SET_STATE = "SET_STATE"
export const CHANGE_LIMIT = "CHANGE_LIMIT"
export const CHANGE_PAGE = "CHANGE_PAGE"
export const CHANGE_OFFSET = "CHANGE_OFFSET"

export default {
    SET_STATE,
    CHANGE_LIMIT,
    CHANGE_PAGE,
    CHANGE_OFFSET
}