import React, { FormEvent } from 'react'
import { connect } from 'react-redux'
import { ISubscribeForm, IInfoLayer } from '../types'
import ActionButton from './ActionButton'
import { subscribeFormInitState } from '../redusers/initState'
import axios from 'axios'
import { showInfoLayer } from '../actions'
import { SUBSCRIBE } from '../constants'

const _re_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const mapDispatchToProps = (dispatch:any) => {
    return {
        showInfoLayer:(payload:IInfoLayer) => {dispatch(showInfoLayer(payload))},
    }
}

interface ISubscribeFormExt extends ISubscribeForm {
    showInfoLayer:(payload:any) => void,
}

class SubscribeForm extends React.Component<ISubscribeFormExt, ISubscribeForm> {

    constructor(props:ISubscribeFormExt, state:ISubscribeForm) {
        super(props, state)

        this.state = subscribeFormInitState

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.validateEmail = this.validateEmail.bind(this)
    }

    validateEmail(email:string) {
        return _re_email.test(email)
    }

    handleSubmit(_e:FormEvent<HTMLFormElement>) {
        const { value } = this.state
        const { showInfoLayer } = this.props
        if (this.validateEmail(value)) {
            axios.post(SUBSCRIBE, {email:value}).then(() => {
                showInfoLayer({
                    text:"Ви підписались на розсилку",
                    timer:10,
                })
            }).catch(() => {
                showInfoLayer({
                    text:"Помилка на сервері, повторіть спробу пізніше",
                    timer:10,
                })
            }).finally(() => {
                this.setState(subscribeFormInitState)
            })
        } else {
            showInfoLayer({
                text:"Введіть коректну адресу",
                timer:3,
            })
        }
    }

    handleChange(e:any) {
        this.setState({value: e.target.value})
    }

    render() {
        const { value } = this.state
        return (
            <div className="SubscribeForm">
                <div className="input-group">
                    <input
                        type="text"
                        id="searchInput"
                        value={value}
                        className="form-control input"
                        aria-label="Sarch in warehouses"
                        onChange={this.handleChange}
                        placeholder="Ваш email"
                    />
                    <div className="input-group-append">
                        <ActionButton
                            text={"Підписатись"}
                            classList={["default-button", "button"]}
                            action={this.handleSubmit}
                            iconName={""}
                            iconSvgSrc=""
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(SubscribeForm)